.container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 1rem;
}

.input {
  padding: 0.5em;
}
