.container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
}

i {
  padding: 0 0.2em;
}
.container {
  background-color: transparent;
  display: flex;
  border: none;
  outline: none;
  color: inherit;
  text-decoration: none;
}

.container:hover {
  opacity: 0.8;
  cursor: default;
}

.container:active {
  opacity: 0.6;
}

.container:focus {
  background-color: rgba(255, 255, 255, 0.2);
}

.inner {
  padding: 3vh 2vw;
  display: flex;
}
