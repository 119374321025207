.container {
  flex: 1;
  max-width: 400px;
}

.form {
  display: flex;

  flex-direction: column;
  justify-content: center;
  padding: 1em;
}

.form > div {
  margin: 0.5em;
}

.submit {
  flex: 1;
}

.logocontainer {
  padding: 1em;
}
