.container {
  display: flex;
  flex-wrap: wrap;
  flex-basis: 100%;
}

.numbersContainer {
  display: flex;
  flex-basis: 100%;
  flex-wrap: wrap;
}

.numbersContainer > div {
  flex-shrink: 1;
  flex-grow: 1;
  flex-basis: 50%;
  min-width: 150px;
}

.container > div {
  flex-shrink: 1;
  flex-grow: 1;
  flex-basis: 300px;
}

.card {
  margin: 1em;
}
