.input {
  width: 100%;
}
.container {
  height: 50vh;
  display: flex;
  flex-direction: column;
}

.searchBar {
  display: flex;
  flex-direction: column;
}

.textInput {
  flex-basis: 65%;
}

.actionSearch {
  flex-basis: 35%;
}
