.container {
  display: flex;
  height: 100%;
  flex: 1;
  flex-direction: column;
}

.list {
  list-style: none;
}
