.container {
  display: flex;
  flex-direction: column;
}

.rectificationContainer {
  display: flex;
  flex-wrap: wrap;
  flex-basis: 100%;
}

.faultActionContainer {
  display: flex;
  flex-wrap: wrap;
  flex-basis: 100%;
  flex-grow: 1;
}

.faultActionContainer > div {
  flex-shrink: 1;
  flex-grow: 1;
  flex-basis: 300px;
}

.item {
  padding: 0.5em;
  border-bottom: solid 1px silver;
  text-decoration: none;
  outline: none;
}
.item:hover {
  opacity: 0.8;
}

.item:active {
  opacity: 0.6;
}

.item:focus {
  opacity: 0.7;
}

.card {
  margin: 1em;
}

.descriptionAndWarranty {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  align-items: center;
}

.descriptionContainer {
  flex-grow: 1;
}

.partContainer {
  display: flex;
  align-items: center;
  flex-grow: 1;
  flex-wrap: wrap;
}

.part {
  flex-basis: 300px;
  flex-grow: 5;
}

.expiry {
  flex-shrink: 1;
  flex-grow: 1;
}

.description {
  flex-grow: 1;
}

input {
  flex-grow: 1;
}
