.reportContainer {
  border: 1px solid #000;
  max-width: 800px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
}

.section {
  margin-bottom: 20px;
}

.sectionTitle {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 15px;
}

.performanceSectionRow {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}

.im,
.rollerBrake,
.decelerometer,
.imsTitle {
  font-size: 0.8em;
  grid-column: span 2;
  border: 1px solid black;
  padding: 4px;
}

.roadConditions,
.roadSpeedLimiter {
  grid-column: span 6;
  border: 1px solid black;
  padding: 4px;
}

.imComments,
.loadCondition,
.loadConditionTitle {
  border: 1px solid black;
  grid-column: span 4;
  padding: 4px;
}

.row {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}

.label {
  font-weight: bold;
  min-width: 200px;
}

.value {
  color: #000;
}

.lockSection {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  margin: 15px 0;
}

.lockItem {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.lockLabel {
  font-size: 0.9rem;
  color: #666;
}

.lockValue {
  font-weight: bold;
}

.comments {
  margin-top: 15px;
  display: flex;
  align-items: center;
}

.overallComments {
  margin-top: 20px;
  border: 1px solid black;
  padding: 0.5em;
  min-height: 35px;
}

.overallCommentsTitle {
  font-size: 1em;
  margin: 0;
  padding: 0;
}

.temperatureSection {
  border: 1px solid #ccc;
  padding: 4px;
  margin-top: 0.5em;
}

.temperatureTitle {
  font-weight: bold;
  margin-bottom: 4px;
}

.temperatureGrid {
  display: grid;
  margin-bottom: 15px;
}

.gridHeader,
.gridRow {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  text-align: center;
  border: 1px solid black;
}

.gridHeader {
  font-weight: bold;
  background-color: #f5f5f5;
  padding: 5px 0;
}

.gridRow span {
  padding: 5px;
  border: 1px solid black;
}

.dateAssessedLabel {
  padding: 5px;
  border: 1px solid black;
  grid-column: span 2;
}

.dateAssessedValue {
  padding: 5px;
  border: 1px solid black;
  grid-column: span 8;
}
