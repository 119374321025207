.checksContainer {
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(320px, 1fr)); */
  grid-gap: 1rem;
  grid-template-columns: none;
  grid-template-areas:
    "cab-interior ground-level"
    "cab-interior under-alongside-vehicle"
    "cab-exterior under-alongside-vehicle"
    "engine-compartment under-alongside-vehicle"
    "tyres under-alongside-vehicle";
}

@media (max-width: 800px) {
  .checksContainer {
    display: flex;
    flex-direction: column;
  }
}

/* @media (min-width: 1400px) {
  .checksContainer {
    grid-template-areas:
      "cab-interior ground-level under-alongside-vehicle"
      "cab-interior cab-exterior under-alongside-vehicle"
      " engine-compartment tyres under-alongside-vehicle"
      " . . under-alongside-vehicle";
  }
} */

.checkContainer {
  object-fit: cover;
  height: fit-content;
}
