.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.card {
  margin: 1em;
  width: 100%;
}

.input {
  background-color: white;
}
