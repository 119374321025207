.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
}

.reset {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding-bottom: 1em;
}

.form {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  padding: 1em;
}

.form > div {
  margin: 0.5em;
}

.submit {
  flex: 1;
}

.logocontainer {
  padding: 1em;
}
