.container {
  display: flex;
  flex: 1;
  height: fit-content;
  flex-wrap: wrap;
  justify-content: stretch;
  align-items: flex-start;
  max-width: 1500px;
}

.card {
  display: flex;
  flex-direction: column;
  margin: 1em;
  flex-shrink: 1;
  flex-grow: 1;
}

.infoFields {
  display: flex;
  flex-direction: column;
}

.info {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-grow: 1;
}

.rectifications {
  flex-shrink: 1;
  flex-grow: 1;
  display: flex;
}

.tyres {
  flex-shrink: 1;
  display: flex;
}

.checks {
  display: flex;
  flex-grow: 1;
}
