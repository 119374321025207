.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem;
}

.input {
  padding: 0.5em;
}

.header {
  display: flex;
  flex-wrap: wrap;
}

.inputContainer {
  flex-grow: 1;
}

.createContainer {
  flex-grow: 0;
  display: flex;
}
