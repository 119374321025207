.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.cardscontainer {
  display: flex;
  flex: 1;
  height: 100%;
  flex-wrap: wrap;
  justify-content: stretch;
  align-items: flex-start;
  overflow-y: auto;
}

.past {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}

.card {
  margin: 1em;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  flex-grow: 1;
  min-width: 220px;
  flex-basis: 28%;
}

.vehicleInfo {
  display: flex;
  width: 100%;
}

.historydaterange {
  display: flex;
  margin-bottom: 0.5rem;
}
