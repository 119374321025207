.container {
  display: flex;
  flex-wrap: wrap;
  flex-basis: 100%;
}

.dateAssessed {
  display: flex;
  flex-basis: 100%;
  margin-top: 1em;
}

.row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 1em 1em;
  column-gap: 1em;
}

.column2 {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 48%;
  row-gap: 0.5em;
}

.column3 {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 31%;
  row-gap: 0.5em;
}

.tempsOuterContainer {
  display: flex;
  flex-wrap: wrap;
  padding: 1em;
  gap: 1em;
}

.osTemps,
.nsTemps {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.card {
  margin-bottom: 1em;
}

.input {
  width: 100%;
  flex-grow: 1;
  background-color: white;
  margin: 0;
}
