.container {
}

.tyresContainer {
  display: flex;
}

.onVehicleTyres {
  display: grid;
  align-items: center;
  grid-gap: 1rem;
  grid-template-columns: none;
  grid-template-areas:
    "tyre1 tyre3 tyre7 tyre11"
    ". . tyre6 tyre10"
    ". . tyre5 tyre9"
    "tyre0 tyre2 tyre4 tyre8";
}

.tyreOuterContainer {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 0.5em;
  border: 0.1em solid lightgrey;
}

.tyreInnerContainer {
  display: flex;
  justify-content: center;
}

.offVehicleTyres .tyreInnerContainer {
  flex-direction: column;
}

.offVehicleTyres {
  display: flex;
  align-items: center;
  padding: 0 0 0 2rem;
}

.offVehicleTyres > div {
  flex-direction: column;
}

@media (max-width: 700px) {
  .tyresContainer {
    display: flex;
    flex-direction: column;
  }

  .tyreOuterContainer {
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .offVehicleTyres > div {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
  .onVehicleTyres {
    display: grid;
    align-items: center;
    grid-gap: 1em 0.1em;
    grid-template-columns: none;
    grid-template-areas:
      "tyre0 . . tyre1"
      "tyre2 . . tyre3"
      "tyre4 tyre5 tyre6 tyre7"
      "tyre8 tyre9 tyre10 tyre11";
  }

  .offVehicleTyres {
    align-items: center;
    padding: 2em 10% 0 10%;
  }
}
