.container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.logomenu {
  display: flex;
  flex-direction: column;
  padding: 2vmin 0;
}

.toplinks {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border-top: 0.1em solid darkslategrey;
  border-bottom: 0.1em solid darkslategrey;
}

.bottomlinks {
  display: flex;
  flex-direction: column;
}
