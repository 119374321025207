.container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bolder;
}

.inline {
  display: "inline";
}
